.mc-product-list, .mc-product-item {
  list-style-type: none; }
.mc-product-item {
  position: relative; }
  .mc-product-item::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 61%; }
  .mc-product-item h2, .mc-product-item p {
    transition: color .25s ease-out; }
  .mc-product-item h2 {
    font-size: 2.3rem;
    color: #313d44;
    margin-bottom: 1rem; }
  .mc-product-item p {
    font-size: 1.3rem;
    color: #969ea3; }
.mc-product-picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 1rem;
  z-index: -1;
  transition: opacity .25s ease-out;
  opacity: 0; }
  .mc-product-picture::before {
    border-radius: 1rem;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); }
.mc-product-icon {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(25% + 1.4rem);
  padding-top: 25%;
  border: 0.7rem solid #e8e8e8;
  border-radius: 50%;
  background: #f6a800;
  transform: translateX(-50%) translateY(-28%); }
  .mc-product-icon img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 60%;
    max-height: 60%;
    transform: translateX(-50%) translateY(-50%); }
.mc-product-link {
  text-decoration: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 23% 10% 5%;
  text-align: center;
  background: white;
  border-radius: 1rem;
  transition: color 0.25s ease-out, transform 1.4s ease-out; }
  .mc-product-link:hover .mc-product-picture {
    opacity: 1; }
  .mc-product-link:hover h2, .mc-product-link:hover p {
    color: white; }

.pageHome .mc-product {
  background: #eeeeee;
  position: relative;
  z-index: 1;
  padding: 4rem 0; }
  @media (max-width: 1080px) {
    .pageHome .mc-product {
      padding: 3%; } }
  .pageHome .mc-product::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../images/dist/pattern.png");
    z-index: 0; }
  .pageHome .mc-product > * {
    position: relative;
    z-index: 1; }
  @media (max-width: 700px) {
    .pageHome .mc-product .u-wrapper {
      max-width: 40rem; } }
  .pageHome .mc-product-title, .pageHome .mc-product-desc {
    max-width: 60rem;
    margin: 0 auto; }
  .pageHome .mc-product-title {
    font-size: 4rem;
    color: #313d44;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center; }
  .pageHome .mc-product-desc {
    font-size: 2rem;
    font-weight: 300;
    text-align: center; }
  .pageHome .mc-product-list {
    margin-top: 4rem; }
    @media (min-width: 701px) {
      .pageHome .mc-product-list {
        display: flex;
        flex-wrap: wrap; } }
    @media (min-width: 701px) and (max-width: 960px) {
      .pageHome .mc-product-list {
        justify-content: space-around; } }
  .pageHome .mc-product-item {
    margin-top: 2rem;
    width: 31.5%;
    margin-bottom: 2.75%; }
    @media (min-width: 961px) {
      .pageHome .mc-product-item {
        margin-right: 2.75%; }
        .pageHome .mc-product-item:nth-child(3n) {
          margin-right: 0; } }
    @media (min-width: 801px) and (max-width: 960px) {
      .pageHome .mc-product-item {
        min-width: 31rem; } }
    @media (min-width: 701px) and (max-width: 800px) {
      .pageHome .mc-product-item {
        width: 46%; } }
    @media (max-width: 700px) {
      .pageHome .mc-product-item {
        min-width: 31rem; } }
    .pageHome .mc-product-item .icon-card {
      width: 100%;
      display: block;
      height: 100%;
      fill: white;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 1.4s ease-out; }
  .pageHome .mc-product-link {
    box-shadow: 0 0.2rem 0.2rem -0.2rem rgba(0, 0, 0, 0.7); }

@media (min-width: 601px) and (max-width: 960px) {
  .mc-product--type {
    width: 50%;
    order: 2; } }
@media (max-width: 600px) {
  .mc-product--type {
    width: 100%; } }
.mc-product--type .mc-product-item {
  margin-top: 3rem; }
  @media (max-width: 600px) {
    .mc-product--type .mc-product-item {
      margin-top: 10vw; } }
  .mc-product--type .mc-product-item::before {
    padding-top: 80%; }
    @media (max-width: 600px) {
      .mc-product--type .mc-product-item::before {
        padding-top: 50%;
        min-height: 18rem; } }
.mc-product--type .mc-product-title {
  font-size: 2rem;
  color: #313d44;
  background: #f9f9f9;
  display: block;
  padding: 1.2rem 2rem; }
.mc-product--type .mc-product-link {
  padding: 23% 9% 5%; }
.mc-product--type .swiper-button-prev::before,
.mc-product--type .swiper-button-next::before {
  font-size: 2.5rem; }
