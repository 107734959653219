.bl [class|="bl-category-label"],
.bl [class|="bl-category-parent-label"] {
  border-left: 8px solid #97c00e; }

#bl-recap-payment-paymode .bl-recap-elem.on .bl-recap-paymode-button button,
.ip-bankTransfer-popup .ip-bankTransfer-finish a,
.bl-product .bl-product-picture.pager-active img {
  border: 1px solid #97c00e; }

.bl-list-categories [class|="bl-category-link"]:hover span,
.bl-list-category-detail .bl-category-link:hover span {
  background-color: #97c00e; }

.bl {
  margin-bottom: 2rem; }

.bl [class|="bl-category-label"] a,
.bl [class|="bl-category-label"],
.bl [class|="bl-category-parent-label"],
.bl-list-products-multiple-add-to-card-button,
.bl-product-add-to-cart-button,
.bl-list-products li .bl-product-add-to-cart,
.bl-list-items li .bl-product-add-to-cart,
.bl-my-cart-finish-order input.submit,
.bl-my-cart-finish-order button.submit,
.bl-list-items li.bl-item .bl-product-add-to-cart,
.bl-subscribe-user-submit input#bl-cart-information-request,
.bl-list-products-multiple-add-to-card-button input,
.bl-product-add-to-cart-button input,
.bl-subscribe-user-submit:before,
.bl-my-cart div.bl-my-cart-finish-order:before,
.bl-product .bl-product-add-to-cart-button:before,
.bl-list-products li.bl-mode-light .bl-product-add-to-cart,
.bl-list-items li.bl-mode-light .bl-product-add-to-cart {
  color: white; }

.bl-product-price .bl-products-promoted,
.bl-list-items li div.bl-item-price div.bl-products-list-promoted,
.bl-list-products li div.bl-item-price div.bl-products-list-promoted,
.bl-list-items li div.bl-light-price-container div.bl-products-list-promoted,
.bl-list-products li div.bl-light-price-container div.bl-products-list-promoted,
.bl-label-input {
  color: #97c00e; }

.bl-product-price div + div {
  color: #97c00e; }

#bl-recap-payment-paymode #bl-recap-payment-finish > button,
.bl-list-products-multiple-add-to-card-button,
.bl-product-add-to-cart-button,
.bl-my-cart-finish-order input.submit,
.bl-my-cart-finish-order button.submit,
.bl-list-items li.bl-item .bl-product-add-to-cart,
.bl-subscribe-user-submit input,
.bl-subscribe-user-submit input#bl-cart-information-request,
.bl-popup-detail-contener a,
.bl-list-items li.bl-mode-list .bl-product-add-to-cart,
.bl-list-items li.bl-mode-light .bl-product-add-to-cart,
.bl-list-items li.bl-mode-mosaic .bl-product-add-to-cart,
.bl-list-products li.bl-mode-list .bl-product-add-to-cart,
.bl-list-products li.bl-mode-light .bl-product-add-to-cart,
.bl-list-products li.bl-mode-mosaic .bl-product-add-to-cart {
  background-color: #97c00e; }

#bl-recap-payment-paymode #bl-recap-payment-finish > button:hover,
#bl-recap-payment-paymode .bl-recap-elem.on .bl-recap-paymode-button button > div.radio::before,
.bl-list-products-multiple-add-to-card-button:hover,
.bl-product-add-to-cart-button:hover,
.bl-my-cart-finish-order:hover input.submit,
.bl-my-cart-finish-order:hover button.submit,
.bl-subscribe-user-submit:hover input#bl-cart-information-request,
.bl-list-items li.bl-item .bl-product-add-to-cart:hover,
.bl-subscribe-user-submit input:hover,
.bl [class|="bl-category-label"],
.bl [class|="bl-category-parent-label"],
.bl-popup-detail-contener a:hover,
.bl-list-items li .bl-product-add-to-cart:hover {
  background-color: #97c00e; }

.bl-my-cart td.bl-my-cart-description div.bl-my-cart-title,
.bl-my-cart td.description div.title,
.bl-my-cart th.bl-my-cart-price,
.bl-my-cart th.price,
.bl-my-cart p.bl-recap-intertitre span,
.bl-my-cart-product-popup .bl-popup-product-title,
.bl-my-cart-product-popup div span,
.bl-recap-contener .bl-recap-etape.active,
div.bl-subscribe span#termsOfSale-label a,
div.bl-subscribe fieldset legend,
.bl-product-price,
.bl-list-products li h3 a,
.bl-list-items li h3 a,
.bl-product-price-list,
.bl-list-items li.bl-item div.bl-item-price,
.bl-product-shipping,
.bl-product-add-to-cart-quantity label,
.bl-list-sous-categories a:hover,
.bl-my-cart-product-popup .bl-popup-product-title a,
.bl-product-title-description,
.bl-product-price div,
.bl-product-price .promoted,
.bl-product-main-title h2,
.ip-checks-popup .ip-checks-finish a,
.ip-checks-popup .ip-checks-title,
.bl-my-cart-label.total,
.bl-my-cart-total,
div#bl-information-request-div fieldset legend,
.bl-product-criteria > .label,
.ip-bankTransfer-popup .ip-bankTransfer-finish a,
.bl-list-sous-categories a {
  color: #313d44;
  margin: 0; }

.bl-list-items li.bl-mode-light h3 a:hover,
.bl-list-products li.bl-mode-light h3 a:hover,
.bl-list-items li.bl-mode-list2 h3 a:hover,
.bl-list-products li.bl-mode-list2 h3 a:hover,
.bl-list-items li.bl-mode-mosaic2 h3 a:hover,
.bl-list-products li.bl-mode-mosaic2 h3 a:hover,
.bl-product-price div + div,
.bl-product-price .promoted.new_price {
  color: #97c00e; }

.bl-recap-contener .bl-recap-etape {
  color: #97c00e; }

.bl-list-products .bl-mode-list h3.bl-title-1-line {
  height: auto; }
