.mc-encart {
  background: white;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  overflow: hidden; }
  .mc-encart-title {
    font-size: 3rem;
    color: #313d44;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center; }
    .mc-encart-title span {
      font-size: 1.6rem;
      font-weight: 300;
      text-transform: uppercase;
      display: block; }
  .mc-encart-list, .mc-encart-item {
    list-style-type: none; }
  .mc-encart-item {
    position: relative;
    display: inline-block;
    overflow: hidden; }
    .mc-encart-item:nth-child(1) .mc-encart-link::before {
      background: #97c00e; }
    .mc-encart-item:nth-child(2) .mc-encart-link::before {
      background: #0c82bb; }
    .mc-encart-item:nth-child(3) .mc-encart-link::before {
      background: #9c8c81; }
    .mc-encart-item:nth-child(4) .mc-encart-link::before {
      background: #f6a800; }
    .mc-encart-item::before {
      content: '';
      display: block;
      width: 100%; }
    .mc-encart-item h2, .mc-encart-item p {
      transition: color .25s ease-out; }
    .mc-encart-item h2 {
      font-size: 3rem;
      color: white;
      font-weight: 800;
      text-align: center;
      line-height: 1;
      margin: 1rem 0; }
      .mc-encart-item h2 span {
        font-size: 1.8rem;
        font-weight: 300;
        text-transform: uppercase;
        display: block; }
    .mc-encart-item p {
      font-size: 1.3rem;
      color: white; }
  .mc-encart-picture {
    position: absolute;
    height: 100%;
    background-size: cover;
    z-index: 0; }
  .mc-encart-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    transition: transform .25s ease-out; }
    @media (min-width: 1081px) {
      .mc-encart-content {
        position: absolute; } }
    @media (min-width: 1081px) {
      .mc-encart-content {
        max-width: 40rem; } }
    @media (max-width: 1200px) {
      .mc-encart-content {
        width: 90%; } }
  .mc-encart-link {
    height: 100%;
    display: block;
    position: relative;
    text-decoration: none;
    z-index: 10;
    text-align: center;
    transition: color 0.25s ease-out, transform 1.4s ease-out; }
    .mc-encart-link::before, .mc-encart-link::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: opacity .25s ease-out; }
    .mc-encart-link::before {
      opacity: 0.65; }
    .mc-encart-link::after {
      background: black;
      opacity: 0; }
  .mc-encart-item:hover .mc-encart-link::before {
    opacity: 0; }
  .mc-encart-item:hover .mc-encart-link::after {
    opacity: 0.65; }

.pageHome .mc-encart-desc {
  font-size: 1.6rem;
  text-align: center; }
@media (min-width: 1081px) {
  .pageHome .mc-encart-list {
    width: 106%;
    flex-shrink: 0; } }
@media (max-width: 1080px) {
  .pageHome .mc-encart-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap; } }
@media (min-width: 601px) and (max-width: 1080px) {
  .pageHome .mc-encart-item {
    width: 50%; } }
@media (max-width: 600px) {
  .pageHome .mc-encart-item {
    width: 100%; } }
@media (min-width: 1081px) {
  .pageHome .mc-encart-item {
    width: 25%;
    transform: skewX(-10deg); }
    .pageHome .mc-encart-item:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      background: white;
      width: 1.2rem;
      z-index: 10;
      transform: translateX(0.3rem) skewX(0.5deg); } }
@media (min-width: 1081px) {
  .pageHome .mc-encart-item::before {
    padding-top: 125.98%; } }
@media (max-width: 1080px) {
  .pageHome .mc-encart-item::before {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    transition: transform 0s ease 0.3s; } }
@media (min-width: 1081px) {
  .pageHome .mc-encart-item:first-child .mc-encart-content {
    left: 55%; }
  .pageHome .mc-encart-item:last-child .mc-encart-content {
    left: 45%; } }
@media (min-width: 1081px) {
  .pageHome .mc-encart-link {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0; } }
@media (max-width: 1080px) {
  .pageHome .mc-encart-link {
    pointer-events: none;
    transition: pointer-events 0s ease .2s; } }
.pageHome .mc-encart-picto {
  width: 9.2rem;
  height: 9.2rem;
  border-radius: 50%;
  background: white;
  position: relative;
  display: inline-block; }
  .pageHome .mc-encart-picto img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 60%;
    max-height: 60%;
    transform: translateX(-50%) translateY(-50%); }
.pageHome .mc-encart-desc {
  position: relative; }
  @media (max-width: 1080px) {
    .pageHome .mc-encart-desc {
      position: absolute;
      opacity: 0;
      transition: opacity .25s ease-out; } }
  .pageHome .mc-encart-desc span {
    display: block;
    transform-origin: top center;
    transition: transform .25s ease-out; }
    @media (min-width: 1081px) {
      .pageHome .mc-encart-desc span {
        transform: scale(0); } }
  .pageHome .mc-encart-desc::before {
    content: '';
    position: absolute;
    top: 0%;
    left: 50%;
    width: 4.6rem;
    height: .5rem;
    background: white;
    transform: translateX(-50%);
    transform-origin: top center;
    transition: transform .25s ease-out, opacity 0.15s ease-out .1s; }
.pageHome .mc-encart-picture {
  top: 50%;
  left: 50%;
  width: 125%; }
  @media (min-width: 1081px) {
    .pageHome .mc-encart-picture {
      transform: translateX(-50%) translateY(-50%) skewX(10deg); } }
  @media (max-width: 1080px) {
    .pageHome .mc-encart-picture {
      transform: translateX(-50%) translateY(-50%); } }
.pageHome .mc-encart-content {
  padding: 10%; }
  @media (min-width: 1081px) {
    .pageHome .mc-encart-content {
      transform: translateX(-50%) skewX(10deg);
      left: 47.5%;
      top: 20%; } }
@media (max-width: 1080px) {
  .pageHome .mc-encart-item:hover::before {
    transform: scaleX(0); } }
.pageHome .mc-encart-item:hover .mc-encart-link {
  pointer-events: all; }
@media (min-width: 1081px) {
  .pageHome .mc-encart-item:hover .mc-encart-content {
    transform: translateX(-50%) translateY(-15%) skewX(10deg); } }
@media (min-width: 1081px) {
  .pageHome .mc-encart-item:hover .mc-encart-desc span {
    transform: scale(1); } }
@media (max-width: 1080px) {
  .pageHome .mc-encart-item:hover .mc-encart-desc {
    position: relative;
    opacity: 1; } }
.pageHome .mc-encart-item:hover .mc-encart-desc::before {
  transform: translateX(-50%) scaleX(5) scaleY(10);
  opacity: 0;
  transition: transform .25s ease-out, opacity 0.1s ease-out; }

@media (min-width: 601px) and (max-width: 960px) {
  .pageType .mc-encart {
    width: 100%;
    order: 1;
    margin: 2rem 0; } }
@media (max-width: 600px) {
  .pageType .mc-encart {
    width: 100%; } }
.pageType .mc-encart-list, .pageType .mc-encart-item {
  display: block;
  width: 100%; }
@media (max-width: 960px) {
  .pageType .mc-encart-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap; } }
@media (min-width: 961px) {
  .pageType .mc-encart-item:not(:first-child) {
    margin-top: 1rem; } }
.pageType .mc-encart-item::before {
  padding-top: 40%; }
.pageType .mc-encart-item h2 {
  font-size: 2.4rem; }
@media (min-width: 601px) and (max-width: 960px) {
  .pageType .mc-encart-item {
    width: 50%; } }
@media (max-width: 600px) {
  .pageType .mc-encart-item {
    width: 100%; } }
.pageType .mc-encart-link {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }
.pageType .mc-encart-picto {
  display: none; }
.pageType .mc-encart-picture {
  top: 0;
  left: 0;
  width: 100%; }
.pageType .mc-encart-content {
  top: 0;
  left: 0;
  height: 100%; }
