.nlBanner {
  background: #f6a800; }
  .nlBanner .u-wrapper {
    min-height: 10.5rem;
    display: flex;
    align-items: center;
    justify-content: center; }
  .nlBanner p {
    font-size: 1.8rem;
    color: white; }
    @media (max-width: 1080px) {
      .nlBanner p {
        font-size: 0; } }
    .nlBanner p span {
      font-weight: 600; }
      @media (max-width: 1080px) {
        .nlBanner p span {
          font-size: 1.8rem; } }
  .nlBanner a {
    display: inline-block;
    color: white;
    background: #f6a800;
    border: 1px solid #f6a800;
    outline: none;
    border-radius: .5rem;
    font-weight: 400;
    text-decoration: none;
    line-height: 1.1;
    margin: 0.5em 0;
    padding: 0.75em 1.5em 0.7em;
    text-transform: uppercase;
    font-size: 1.4rem;
    z-index: 1;
    cursor: pointer;
    transition: all .25s ease-out;
    transition-property: border, background, color;
    position: relative;
    padding: 1em 3em 1em 1.5em;
    border-color: white;
    margin-left: 2rem; }
    .nlBanner a::after {
      content: '';
      display: inline-block;
      width: 0.8em;
      height: 0.8em;
      border-right: 0.2rem solid white;
      border-bottom: 0.2rem solid white;
      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%) rotateZ(-45deg); }
    .nlBanner a:hover {
      background: transparent;
      color: white; }
    .nlBanner a:hover {
      color: #f6a800;
      background: white; }
      .nlBanner a:hover::after {
        border-color: currentColor; }
