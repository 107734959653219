.mcDiaporama {
  height: 100% !important; }
  .mcDiaporama .mcDiaporama-wrapper, .mcDiaporama .bx-wrapper, .mcDiaporama .bx-viewport, .mcDiaporama .mcDiaporama-list, .mcDiaporama .mcDiaporama-item {
    width: 100% !important;
    height: 100% !important; }
  .mcDiaporama .mcDiaporama-list {
    margin: 0;
    padding: 0; }
  .mcDiaporama .mcDiaporama-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    font-family: "object-fit: cover; object-position: center";
    z-index: -1; }
  .mcDiaporama .mcDiaporama-item {
    position: relative;
    z-index: 5;
    color: white; }
    @media (max-width: 1080px) {
      .mcDiaporama .mcDiaporama-item {
        padding: 3%; } }
    .mcDiaporama .mcDiaporama-item::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 0; }
    .mcDiaporama .mcDiaporama-item > div, .mcDiaporama .mcDiaporama-item > a {
      display: block;
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%; }
    .mcDiaporama .mcDiaporama-item--hasLink a {
      text-decoration: none;
      color: inherit; }
  .mcDiaporama h2 {
    color: white;
    font-size: 4rem;
    font-weight: 300;
    background: rgba(115, 142, 154, 0.8);
    text-transform: uppercase;
    display: inline-block;
    padding: .3rem 1rem .8rem; }
  .mcDiaporama p {
    font-size: 2rem;
    margin-top: 2rem; }
  .mcDiaporama .button {
    display: inline-block;
    color: white;
    text-transform: uppercase;
    background: #f6a800;
    border: 0.15em solid #f6a800;
    text-decoration: none;
    margin: 0.5em 0;
    padding: 1em 2em;
    font-size: 1.6rem;
    border-radius: 0.3em;
    z-index: 1;
    transition: all .25s ease-out;
    transition-property: border, background, color; }
    .mcDiaporama .button:hover {
      background: rgba(246, 168, 0, 0.3);
      color: white; }
  .mcDiaporama .u-wrapper {
    text-align: center;
    max-width: 60rem;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
