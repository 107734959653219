@media (min-width: 1081px) {
  .pageHome #zone-actus {
    background: linear-gradient(to right, white 50%, #ededed 50%); } }
.pageHome #zone-actus .u-wrapper {
  max-width: 120rem;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1080px) {
    .pageHome #zone-actus .u-wrapper {
      flex-wrap: wrap; } }
.pageHome #zone-actus .actusFolder {
  position: relative; }
  @media (min-width: 1081px) {
    .pageHome #zone-actus .actusFolder {
      padding: 2rem 6%; } }
  @media (max-width: 1080px) {
    .pageHome #zone-actus .actusFolder {
      width: 100%;
      padding: 2rem 3%; } }
  @media (min-width: 1081px) {
    .pageHome #zone-actus .actusFolder-une {
      width: 38.5rem;
      max-width: 32%;
      padding-left: 0; } }
  .pageHome #zone-actus .actusFolder-general {
    background: #f5f5f5; }
    @media (min-width: 1081px) {
      .pageHome #zone-actus .actusFolder-general {
        width: 40.8rem;
        max-width: 36%; } }
    @media (max-width: 700px) {
      .pageHome #zone-actus .actusFolder-general {
        width: 50%; } }
  .pageHome #zone-actus .actusFolder-evenement {
    background: #ededed; }
    @media (min-width: 1081px) {
      .pageHome #zone-actus .actusFolder-evenement {
        width: 40.8rem;
        max-width: 36%;
        padding-right: 0; } }
    @media (max-width: 700px) {
      .pageHome #zone-actus .actusFolder-evenement {
        width: 50%; } }
  .pageHome #zone-actus .actusFolder-title {
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: 800;
    color: #313d44; }
    .pageHome #zone-actus .actusFolder-title::after {
      content: '';
      display: block;
      width: 2.6rem;
      height: .4rem;
      background: #f6a800;
      margin: .5rem 0 2rem; }

@media (min-width: 601px) and (max-width: 960px) {
  .pageType #zone-actus {
    width: 50%;
    order: 3; } }
@media (max-width: 600px) {
  .pageType #zone-actus {
    width: 100%; } }
.pageType #zone-actus .actusFolder {
  margin-top: 3rem; }
  @media (max-width: 960px) {
    .pageType #zone-actus .actusFolder {
      margin: 2rem 0; } }
  .pageType #zone-actus .actusFolder-title {
    font-size: 2rem;
    color: #313d44;
    background: #f9f9f9;
    display: block;
    padding: 1.2rem 2rem; }
