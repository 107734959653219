#extranet fieldset {
  border: none;
  padding: 0;
  margin: 0; }
  #extranet fieldset > * {
    text-align: center; }
  #extranet fieldset > div {
    display: flex;
    align-items: center; }
    @media (max-width: 1080px) {
      #extranet fieldset > div {
        flex-direction: column;
        justify-content: center; } }
    .content #extranet fieldset > div {
      margin-top: 2rem;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 1rem; }
#extranet .input-wrapper {
  position: relative; }
  #extranet .input-wrapper:not(:first-of-type) {
    margin-left: 1rem; }
    @media (max-width: 1080px) {
      #extranet .input-wrapper:not(:first-of-type) {
        margin-left: 0;
        margin-top: 1rem;
        margin-bottom: .5rem; } }
#extranet .input-icon {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  display: inline-block;
  left: .5rem;
  top: 50%;
  transform: translateY(-50%); }
  #extranet .input-icon svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #969ea3; }
#extranet input[type="text"],
#extranet input[type="password"] {
  padding: 0.5rem 2rem 0.3rem 2.5rem;
  border: none;
  font-size: 1rem;
  color: #969ea3; }
  #extranet input[type="text"]:focus,
  #extranet input[type="password"]:focus {
    outline: 1px solid #f6a800; }
  .content #extranet input[type="text"], .content
  #extranet input[type="password"] {
    margin: .5rem;
    min-width: 30rem; }
#extranet input[type="submit"] {
  display: inline-block;
  color: white;
  background: #f6a800;
  border: 1px solid #f6a800;
  outline: none;
  border-radius: .5rem;
  font-weight: 400;
  text-decoration: none;
  line-height: 1.1;
  margin: 0.5em 0;
  padding: 0.75em 1.5em 0.7em;
  text-transform: uppercase;
  font-size: 1.4rem;
  z-index: 1;
  cursor: pointer;
  transition: all .25s ease-out;
  transition-property: border, background, color;
  padding: 0.5rem;
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem; }
  #extranet input[type="submit"]:hover {
    background: white;
    color: #f6a800; }
#extranet-connected {
  text-align: center;
  font-size: 1.4rem; }
  #extranet-connected > * {
    display: inline-block;
    margin-right: 1rem; }
  #extranet-connected #extranet-logout {
    color: #f6a800;
    text-decoration: none; }
