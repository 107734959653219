h2.Titre_H2 {
  color: #313d44;
  margin: .6em 0;
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.15;
  position: relative; }

h3.Titre_H3 {
  line-height: 1.2em;
  color: #4d5458;
  font-size: 3.4rem;
  margin: 0;
  font-weight: 300;
  position: relative; }

h4.Titre_H4 {
  line-height: 1.2em;
  color: #f6a800;
  font-size: 2.2rem;
  margin: .4em 0;
  font-weight: normal;
  text-transform: uppercase;
  position: relative; }

h5.Titre_H5 {
  text-transform: uppercase;
  line-height: 1.2em;
  color: #97c00e;
  font-size: 1.8rem;
  margin: .4em 0;
  font-weight: 600;
  position: relative; }

strong.accroche {
  font-size: 1.8rem;
  font-weight: 600; }

strong.mise_en_avant_1 {
  font-weight: 600;
  color: #97c00e; }

strong.mise_en_avant_2 {
  font-weight: 600;
  color: #f6a800; }

span.surtitre {
  font-size: 2rem;
  font-weight: 900;
  color: #f6a800;
  display: block;
  text-align: center;
  text-transform: uppercase; }

span.barre {
  padding: 0 0.4em;
  background: #ffffff; }

blockquote.citation {
  margin: 1em 0;
  padding: .4em 1em;
  border-left: 0.4rem solid #97c00e;
  background: rgba(0, 0, 0, 0.04); }

span.bouton_1 {
  display: inline-block;
  color: white;
  background: #f6a800;
  border: 1px solid #f6a800;
  outline: none;
  border-radius: .5rem;
  font-weight: 400;
  text-decoration: none;
  line-height: 1.1;
  margin: 0.5em 0;
  padding: 0.75em 1.5em 0.7em;
  text-transform: uppercase;
  font-size: 1.4rem;
  z-index: 1;
  cursor: pointer;
  transition: all .25s ease-out;
  transition-property: border, background, color; }
